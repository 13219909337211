import React from 'react';
import { translate } from "app/core"
import keenImg from 'assets/images/Keen.jpeg'
import './FeedbackAboutTheProgrammeSection.component.scss';

const FeedbackAboutTheProgrammeSection = (): JSX.Element => {
    return(
      <div className="mx-auto bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mt-8 mb-2">

        <section>
          <div className="feedback-container">
            <img className='shadow-md' src={keenImg} alt="Keen" />
            <div className="flex-column">
              <h2 className="nhsuk-heading-m">
                {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME')}
              </h2>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3">
                {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_EXTRACT_1_1')}
              </p>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3">
                {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_EXTRACT_1_2')}
              </p>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3">
                {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_EXTRACT_1_3')}
              </p>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3">
                {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_EXTRACT_1_4')}
              </p>
            </div>
          </div>
          <h3 className="nhsuk-heading-s">
            {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_FIND_OUT_MORE')}
          </h3>
          <details className="nhsuk-details nhsuk-expander">
            <summary className="nhsuk-details__summary">
              <span className="nhsuk-details__summary-text">
                {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_CHRISTINE')}
              </span>
            </summary>
            <div className="nhsuk-details__text">
              <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-padding-left-6">{translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_CHRISTINE_1')}</p>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-padding-left-6">{translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_CHRISTINE_2')}</p>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-padding-left-6">{translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_CHRISTINE_3')}</p>
            </div>
          </details>
          <details className="nhsuk-details nhsuk-expander">
            <summary className="nhsuk-details__summary">
              <span className="nhsuk-details__summary-text">
                {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_TEMITOPE')}
              </span>
            </summary>
            <div className="nhsuk-details__text">
              <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-padding-left-6">{translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_TEMITOPE_1')}</p>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-padding-left-6">{translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_TEMITOPE_2')}</p>
            </div>
          </details>
          <details className="nhsuk-details nhsuk-expander">
            <summary className="nhsuk-details__summary">
              <span className="nhsuk-details__summary-text">
                {translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_KEN')}
              </span>
            </summary>
            <div className="nhsuk-details__text">
              <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-padding-left-6">{translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_KEN_1')}</p>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-padding-left-6">{translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_KEN_2')}</p>
              <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-padding-left-6">{translate('HOME.INFO.FEEDBACK_ABOUT_PROGRAMME_KEN_3')}</p>
            </div>
          </details>
        </section>
      </div>
    )
}

export default FeedbackAboutTheProgrammeSection;
