const translations = {
  EMAIL_HL: 'healthyliving@support.changinghealth.com',
  OPENS_IN_NEW_TAB: 'Opens in new tab',
  OPENS_IN_EMAIL_APP: 'Opens in email application',
  FORM: {
    PLEASE_SELECT: 'Please Select',
    PLEASE_CORRECT_FORM_ERRORS: 'Please correct the form errors'
  },
  HOME: {
    CLICK: 'Click Me',
    LOGIN: 'Login',
    ALREADY_REGISTERED: 'Already registered?',
    LOGIN_TO_HL: 'Login to Healthy Living by clicking the button below. The link will take you to the login page of our programme partner Changing Health.',
    YOU_WILL_NEED: 'You will need your username and password to access the programme.',
    INFO: {
      WHAT_IS: 'What is Healthy Living for people with type 2 diabetes?',
      HEALTHY_LIVING_IS: 'Healthy Living is a free, online NHS programme that supports people to live well with type 2 diabetes.',
      HEALTHY_LIVING_IT_INCLUDES: 'It includes information and advice about:',
      HEALTHY_LIVING_IS_STEP_1: 'type 2 diabetes',
      HEALTHY_LIVING_IS_STEP_2: 'eating well',
      HEALTHY_LIVING_IS_STEP_3: 'becoming more active',
      HEALTHY_LIVING_IS_STEP_4: 'how to look after your body and mind',
      HEALTHY_LIVING_IS_STEP_5: 'other NHS services and support',
      HEALTHY_LIVING_IS_STEP_6: 'living with diabetes, including driving, working and travelling',
      HEALTHY_LIVING_CAN_USE: 'You can use the Healthy Living programme whenever suits you, and for as long as you need - there is no limit to access so you can learn at your own pace. It can also be used alongside any other diabetes programmes or education you may be taking part in.',
      FEEDBACK_ABOUT_PROGRAMME: 'Feedback about the programme',
      FEEDBACK_ABOUT_PROGRAMME_EXTRACT_1_1: "Keen golfer and travel enthusiast Idwal joined Healthy Living a few months ago after recently being diagnosed with type 2 diabetes",
      FEEDBACK_ABOUT_PROGRAMME_EXTRACT_1_2: "Idwal said: “The programme is straight forward and to the point. There are lots of handy tips. It is also realistic, so when you fail – as we probably all do at some point – you are not really alone. You can see that we all suffer small setbacks in our quest to improve our diet and health.",
      FEEDBACK_ABOUT_PROGRAMME_EXTRACT_1_3: "So far, I have lost around 18 lbs and I want to lose another 14. This has been brought about by the changes I have made to my diet after learning more about type 2 diabetes through the Healthy Living programme. It has helped me understand in more detail about all facets of the condition, and helped me when I fall off the wagon. My sleep is better, and I feel much brighter and healthier in my whole being.",
      FEEDBACK_ABOUT_PROGRAMME_EXTRACT_1_4: "To others joining the Healthy Living programme, Idwal advises: “Do it. You can never know too much. It doesn’t take all that much of your time, and the benefits are worth it!” ",
      FEEDBACK_ABOUT_PROGRAMME_FIND_OUT_MORE: 'Find out more about people who have used the Healthy Living programme',
      FEEDBACK_ABOUT_PROGRAMME_CHRISTINE: "Christine, 76 years old",
      FEEDBACK_ABOUT_PROGRAMME_CHRISTINE_1: "Healthy Living user Christine, 76, joined the Healthy Living programme a few months ago on the recommendation of her GP surgey. She said:",
      FEEDBACK_ABOUT_PROGRAMME_CHRISTINE_2: "“I found the registration process for the Healthy Living programme easy. It is full of advice and has reminded me of a lot of things I'd forgotten. It's given me a well-needed incentive to eat healthier and move more, as I had become rather complacent.” ",
      FEEDBACK_ABOUT_PROGRAMME_CHRISTINE_3: "To others starting out on the Healthy Living programme, Christine said: “Go for it. You’ve nothing to lose but a lot to gain. It isn't all hopeless. My Dad was 20 when he was diagnosed, he kept to a strict diet, exercised - and lived to 96!”",
      FEEDBACK_ABOUT_PROGRAMME_TEMITOPE: "Temitope, diagnosed 10 years ago",
      FEEDBACK_ABOUT_PROGRAMME_TEMITOPE_1: "Keen traveller, Temitope was diagnosed with type 2 diabetes ten years ago and has recently joined the Healthy Living programme. He says: ",
      FEEDBACK_ABOUT_PROGRAMME_TEMITOPE_2: "“My doctor recommended the Healthy Living programme to me. I found the learning very insightful. The programme is easy to follow, and a helpful reminder about portion sizes and when to eat certain foods. I am still on the programme and would recommend anyone to join and achieve the best result for your mind and body!”",
      FEEDBACK_ABOUT_PROGRAMME_KEN: "Keith, recently diagnosed",
      FEEDBACK_ABOUT_PROGRAMME_KEN_1: "After recently being diagnosed with type 2 diabetes, camping enthusiast Keith joined Healthy Living to gain as much support as possible.  Keith said: ",
      FEEDBACK_ABOUT_PROGRAMME_KEN_2: "“The programme already adds to my thought processes. It provides lots of information in a simple to understand way. After being on the programme for a few months now, I have seen my HbA1c reduce from 56 mmol to 39 mmol. I've also lost a total of 37lbs, five inches from my waist and my BMI has dropped from 27.1 to 23!”",
      FEEDBACK_ABOUT_PROGRAMME_KEN_3: "For others joining the Healthy Living programme, Keith advises: “Read through. Try out the exercises. Seek the help and assistance you need.”",
      HEALTHY_LIVING_REGISTER_NOW: 'Register now',
      HEALTHY_LIVING_SIGN_UP_NOW: 'Sign up now by completing the registration form.',
      GET_HELP: 'Get help accessing Healthy Living',
      IF_YOU_NEED_HELP: 'If you need help with joining or using the Healthy Living programme, contact us for further support.',
      EMAIL_US_1: 'Email us (Opens in email application)',
      EMAIL_US_2: ', or call 0191 429 6000 if you would prefer to speak to one of our team.',
      IF_YOU_NEED_HELP_CALL: 'If you would prefer to speak to one of our team, call 0191 429 6000.',
      MEDICAL_ADVICE: 'We cannot offer medical advice. The support telephone contact and email address are not monitored by medically trained members of staff.',
      SUPPORT_TEAM_AVAILABLE: 'Our support team are available Monday to Friday, 9am to 5pm.',
      MEDICAL_ADVICE_TITLE: 'Get medical advice',
      CONCERNED_ABOUT_YOUR_HEALTH: 'If you are concerned about your health, please contact a health professional such as your GP.',
      NEED_MEDICAL_HELP_NOW_1: 'If you need medical help now, but it is not an emergency go to ',
      NEED_MEDICAL_HELP_NOW_LINK: '111.nhs.uk',
      NEED_MEDICAL_HELP_NOW_2: ' or call 111 from any landline or mobile phone free of charge.',
      DEAF_PEOPLE_CAN_USE_TEXT: 'Deaf people can use text relay to call 18001 111.',
      HEALTH_CARE_PROFESSIONAL_TITLE: 'Information for healthcare professionals',
      IF_YOU_ARE_HEALTHCARE_PROFESSIONAL: 'If you’re a healthcare professional and are interested in referring people into the programme, contact NHS England at ',
      IF_YOU_ARE_HEALTHCARE_PROFESSIONAL_EMAIL: 'england.digitaldiabetes@nhs.net',
      CARE_FOR_TITLE: 'Information for people caring for or supporting a person with type 2 diabetes',
      FAMILY_MEMBER_COMPONENT: 'Information for friend or family member of someone living with type 2 diabetes',
      CARE_FOR_DESC_1: 'The Healthy Living programme has been designed for people with a diagnosis of type 2 diabetes.',
      CARE_FOR_DESC_2: 'If you are completing the course with or on behalf of someone else, please be aware that the advice and guidance contained in Healthy Living is intended for the person with type 2 diabetes - and not yourself. Consult your own GP before making any lifestyle changes.',
    },
    FORM: {
      REGISTER_FOR_THE_PROGRAMME: 'Register for the programme',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      EMAIL: 'Email',
      DATE_OF_BIRTH: 'Date of birth',
      DATE_OF_BIRTH_EXAMPLE: 'For example, 1 2 1990',
      GENDER: 'Gender',
      GENDER_POSTCODE_DESCRIPTION: 'We collect this data to monitor equity of access to the service.',
      POSTCODE: 'Postcode',
      WHY_ASKING_THIS_INFO: 'Why are we asking for this information ?',
      WHICH_DESCRIBES_YOU: 'Which of the following best describes you?',
      HOW_FIND_OUT_ABOUT_HL: 'How did you find out about Healthy Living?',
      I_AGREE_TO: 'I agree to',
      PRIVACY_POLICY: 'Privacy Policy',
      REGISTER: 'Register',
      BY_FILLING_FORM_YOU_ARE_CONSENTING :'* By filling this form you are consenting to share your personal information with NHS England. Your information will be held confidentially and will not be shared with any third parties. For more information review our ',
      CHANGING_HEALTH: 'Changing Health\'s ',
      TERMS_CONDITIONS: 'Terms & Conditions',
      HEALTHY_LIVING: 'Healthy Living ',
      SERVICE_DESCRIPTION: 'Service Description',
      FAIR_PROCESSING_NOTICE: 'Fair Processing Notice',
      CONSENT_GP: 'Can we tell your GP you have used Healthy Living?',
      CONSENT_GP_DESCRIPTION: 'This will help them keep your health record up to date.',
      YES: 'Yes',
      NO: 'No',
      CONTINUE: 'Continue',
      ERROR: {
        CANT_BE_BLANK_MIN_2: "Can't be blank, is too short (minimum is 2 character)",
        TO_LONG_MAX_50: 'Is too long (maximum are 50 characters)',
        INVALID_CHARACTERS_FIRST_NAME: 'First name uses special characters that are not allowed.',
        INVALID_CHARACTERS_LAST_NAME: 'Last name uses special characters that are not allowed.',
        DATE_OF_BIRTH_REAL_DATE: 'Date of birth must be a real date.',
        EMAIL_ADDRESS_EMPTY: 'Enter an email address in the correct format, like name@example.com',
        POSTCODE_REAL: 'Check that you have typed your postcode correctly.',
        SELECT_ANSWER: 'You must select an answer',
        AGREE_PRIVACY_POLICY: 'You will need to agree to the privacy policy to continue with registration.',
        EMAIL_ADDRESS_IN_USE: 'The provided email address is already in use.',
        USE_A_DIFFERENT_ADDRESS: 'Please use a different email address, or go to ',
        WITH_EXISTING_ACCOUNT: ' to login with your existing email address or reset your password.',
        EMAIL_FORMAT: "The provided email address is not in the correct format.",
        GENERIC: "We weren't able to process your form",
        GENERIC_DESCRIPTION: 'Please try completing and sending the form again. If you are still having problems contact us at ',
        GENDER: 'Select your gender.',
        DESCRIBES_YOU: 'Select the answer that best describes you.',
        HOW_FIND: 'Select where you first heard about the Healthy Living programme.',
        CONSENT_GP: 'Select yes if you want us to tell your GP that you have used the Healthy Living programme.'
      },
    },
    ACCESS_AVAILABLE_ENGLAND: 'Access to this programme is only available in England.',
    YOUR_IP: 'Your IP: ',
    IS_LOCATED_IN: ' is located in ',
    YOURE_TRYING_PROGRAME: 'It looks like you’re trying to sign up to the Healthy Living programme from outside of England. ',
    IF_YOU_NEED_MORE_HELP: 'If you need more help please contact us at ',
  },
  HEADER: {
    LOGIN: 'Login',
    LOGO_HL: 'Healthy Living logo',
    LOGO_NHS: 'Healthy NHS logo',
    TITLE: 'Healthy Living for people with type 2 diabetes',
  },
  FOOTER: {
    ACCESSIBILITY_STATEMENT: 'Accessibility statement',
    PRIVACY_POLICY: 'Privacy policy',
    TERMS_AND_CONDITIONS: 'Terms and conditions',
    SERVICE_DESCRIPTION: 'Service Description',
    FAIR_PROCESSING_NOTICE: 'Fair Processing Notice',
  },
  REGISTERED: {
    CHECK_YOUR_EMAIL: 'Check your email inbox to accept your invitation to Healthy Living',
    CHECK_YOUR_EMAIL_1: 'Open the email and click the ‘Complete registration’ link',
    CHECK_YOUR_EMAIL_2: 'Check your spam or junk folder if you cannot see the email',
    CHECK_YOUR_EMAIL_3: 'The email will be from our delivery partner, Changing Health, at ',
    NO_REPLY_EMAIL: 'team@changinghealth.com.',
    IF_NOT_RECEIVED: 'If you have not received the email within 15 minutes',
    EMAIL_US_FOR_HELP: 'Email us for help at ',
    EMAIL_US_FOR_HELP_LINK: 'healthyliving@support.changinghealth.com',
  },
  AGE: {
    HL_PROGAMME_AVAILABLE_TO: 'The Healthy Living programme is available to people aged 18 years or over.',
    EMAIL_US_FOR_INFORMATION: 'Ask your GP for information about diabetes support available for people under 18 in your area, or email us at ',
    EMAIL_US_FOR_INFORMATION_LINK: 'healthyliving@support.changinghealth.com',
  },
  RISK_TYPE_2: {
    HL_PROGAMME_AVAILABLE_TO: 'Healthy Living is a programme for people with a diagnosis of type 2 diabetes.',
    DESC_0_1: 'You said you are at risk of type 2 diabetes. If this isn’t correct, ',
    DESC_0_2: 'go back',
    DESC_0_3: ' and change your answer.',
    DESC_1: 'People who are at risk of developing type 2 diabetes can access a different NHS service called Healthier You: NHS Diabetes Prevention Programme.',
    DESC_2: 'When blood sugar levels are above the normal range, but not high enough to be diagnosed as having diabetes, this is called non-diabetic hyperglycaemia (NDH) or sometimes known as pre-diabetes.',
    DESC_3: 'Your GP practice can refer you to the Healthier You: NHS Diabetes Prevention Programme if you have had a blood test in the last 12 months confirming pre-diabetes.',
    DESC_4: 'If you do not have a diagnosis of type 2 diabetes or pre-diabetes, complete this short questionnaire to find out your type 2 risk score. It only takes a few minutes. It could be the most important thing you do today.',
    RESOURCES_TYPE_2_DIABETES_LINK: 'Find out your type 2 risk score',
  },
  TYPE_1: {
    TITLE: 'Resources for people living with type 1 diabetes',
    DESC_0_1: 'You said you have type 1 diabetes. If this isn\'t correct, ',
    DESC_0_2: 'go back',
    DESC_0_3: ' and change your answer.',
    DESC_1: 'People who are living with type 1 diabetes can access different NHS services available through the NHS website.',
    DESC_2: 'People living with type 1 diabetes need to manage their condition differently to those living with type 2 diabetes.',
    DESC_3: 'Click on the link below to view the NHS type 1 diabetes page which includes information, support and access to services for people living with type 1 diabetes.',
    VISIT_PAGE: 'Visit the NHS type 1 diabetes page',
  },
  404: {
    NO_MATCH_FOR: 'No match for',
    ERROR_CODE_404: 'Error code 404',
    PLEASE_TRY_AGAIN: 'If you typed in the web address, please check it is correct and try again.'
  }
};

export default translations;
