import React, { useEffect, useState } from 'react';

import './Home.component.scss';
import { translate } from 'app/core';
import ButtonComponent from 'app/shared/components/Button/Button';
import ipApiServiceInstance, { IpApiI } from 'app/shared/services/IpApiService';
import MultiRadioComponent from 'app/shared/components/Input/MultiRadio/MultiRadio';
import { DescribesYouOptions } from 'app/shared/models/registerForm';
import { AlreadyRegisteredSection } from 'app/shared/components';
import { navigate } from 'app/navigation';
import { Col, Row } from 'nhsuk-react-components';
import { BrowserView, MobileView } from 'react-device-detect';
import certificate from 'assets/images/Certificated-logo.jpg';
import FormComponent from './Form/Form.component';
import {InfoComponent, GetHelpComponent, CareForComponent, FamilyMemberComponent} from './Info/Info.component';
import BlockComponent from './Block/Block.component';
import InformationForHealthcareProfessionalSection
  from "../../shared/components/InformationForHealthcareProfessionalSection/InformationForHealthcareProfessionalSection.component";
import FeedbackAboutTheProgrammeSection
  from "../../shared/components/FeedbackAboutTheProgrammeSection/FeedbackAboutTheProgrammeSection.component";

const DescribesYouFormComponent = ({onContinue}: {onContinue: (e: string) => void}): JSX.Element => {
  const [describesYou, setDescribesYou] = useState<string>();
  const describesYouOptions = Object.values(DescribesYouOptions);

  return (
    <div className='mx-auto bg-gray-200 shadow-md rounded px-2 lg:px-8 pt-6 pb-8 nhsuk-u-margin-bottom-2'>
      <h2 id="register-label" className="nhsuk-u-margin-bottom-4 nhsuk-label--m">
        {translate('HOME.FORM.REGISTER_FOR_THE_PROGRAMME')}
      </h2>
      <MultiRadioComponent
        id='describes_you'
        label={translate('HOME.FORM.WHICH_DESCRIBES_YOU')}
        options={describesYouOptions}
        error={false}
        onChange={(e: string): void => setDescribesYou(e)}
      />
      <ButtonComponent className="nhsuk-u-margin-bottom-0" text={translate('HOME.FORM.CONTINUE')} onClick={(): void => onContinue(describesYou || '')} />
    </div>
  )
}

const HomeComponent = (): JSX.Element => {
  const [describesYou, setDescribesYou] = useState<string>();
  const [isCountryAvailable, setIsCountryAvailable] = useState<IpApiI>();
  const [showForm, setShowForm] = useState<boolean>(false);

  // const executeScroll = (): void => document.getElementById('top-form')?.scrollIntoView();

  const onContinue = (describesYouOp: string): void => {
    if (describesYouOp === DescribesYouOptions.Type1 || describesYouOp === DescribesYouOptions.RiskType2) {
      navigate(`/${describesYouOp === DescribesYouOptions.Type1 ? 'type1' : 'risktype2'}` )
      return;
    }

    setDescribesYou(describesYouOp);
    setShowForm(true);
    // NO executeScroll without header banner
    // setTimeout(() => {
    //   executeScroll();
    // });
  }

  const getCountryAvailable = async (): Promise<void> => {
    const ip = await ipApiServiceInstance.getIP();
    if (ip) {
      setIsCountryAvailable(ip as IpApiI);
    }
  };

  useEffect(() => {
    getCountryAvailable();
  }, []);

  if (isCountryAvailable && !isCountryAvailable.isValid) {
    return (
      <div className="nhsuk-u-margin-bottom-4">
        <BlockComponent ip={isCountryAvailable.ip} countryName={isCountryAvailable.countryName} />
      </div>
    );
  }

  return (
    <div id="top-form" className="nhsuk-u-margin-bottom-4">
      {/* <div
        className="nhsuk-warning-callout"
      >
        <h3 className="nhsuk-warning-callout__label">
          <span role="alert">
            <span className="nhsuk-u-visually-hidden">Important: </span>
            Scheduled maintenance
          </span>
        </h3>
        <p>The Healthy Living programme will be unavailable on 25th and 26th November 2024.</p>
      </div> */}
      <div className="bg-white w-full mx-auto p-4 lg:p-8 rounded shadow-xl lg:max-w-5xl">
        {!showForm ? (
          <div>
            <Row>
              <BrowserView>
                <Col width="one-half">
                  <DescribesYouFormComponent onContinue={onContinue} />
                </Col>
                <Col width="one-half">
                  <InfoComponent />
                </Col>
              </BrowserView>
              <MobileView>
                <Col width="one-half">
                  <InfoComponent />
                </Col>
                <Col width="one-half">
                  <DescribesYouFormComponent onContinue={onContinue} />
                </Col>
              </MobileView>
            </Row>
            <FeedbackAboutTheProgrammeSection />
            <InformationForHealthcareProfessionalSection />
            <AlreadyRegisteredSection />
            <img src={certificate} alt="Qismet Certificate" width={200} className="m-3" style={{marginTop: '3rem'}} />
          </div>
          ): (
            <Row>
              <Col width="one-half">
                {describesYou && <FormComponent describesYou={describesYou} />}
              </Col>
              <Col width="one-half">
                {describesYou === DescribesYouOptions.SupportPersonType2 && <CareForComponent />}
                {describesYou === DescribesYouOptions.FriendOrFamilyMemberWithType2Diabetes && <FamilyMemberComponent />}
                <GetHelpComponent />
              </Col>
            </Row>
          )}
      </div>
    </div>
  );
};

export default HomeComponent;
